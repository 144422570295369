<template>
  <div></div>
</template>

<script>
import urls from "@/utils/url";
import { ticketCheck } from "@/api/ticketCheck";
export default {
  created() {
    let ticket = urls.getUrlKey("ticket");
    let url = location.href.substr(0, location.href.indexOf("?"));
    ticketCheck({ url, ticket })
      .then(res => {
        if (res.code === 10000 && res.httpStatus === 200) {
          window.sessionStorage.setItem("userName", res.data.name);
          window.sessionStorage.setItem("token", res.data.token);
          window.sessionStorage.setItem("sid", res.data.sid);

          // this.$store.commit("SetUserRole", JSON.stringify(res.data.roles));
          this.$store.commit("SetNavBarTitle", "角色选择");
          this.$router.push({ path: "/roleSelect" });
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      })
      .catch(error => {
        this.$notify({ type: "warning", message: error.message });
      });
  }
};
</script>
